const axios = require("axios")



export const sendEmailAPI = async (data) => {
  try {
    return await axios({
      url: "https://m533sg538l.execute-api.us-east-1.amazonaws.com/PRD",
      method: "post",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
  } catch (error) {
    console.log(error)
    return error
  }
}
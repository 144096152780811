import React, { useState } from "react"
import { Icon } from "react-icons-kit"
import { whatsapp } from "react-icons-kit/fa/whatsapp"
import { envelopeO } from "react-icons-kit/fa/envelopeO"
import { sendEmailAPI } from "../services/send-email"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"

const Contato = (location) => {

  const url = `https://speedcambrasil.com.br${location.pathname}`

  const regExEmail = new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  )

  const [formName, setFormName] = useState("")
  const [formCompany, setFormCompany] = useState("")
  const [formEmail, setFormEmail] = useState("")
  const [formPhone, setFormPhone] = useState("")
  const [formMessage, setFormMessage] = useState("")
  const [errorFields, setErrorFields] = useState("")
  const [commMethod, setComMethod] = useState("")
  const [zapUrl, setZapUrl] = useState(
    "https://api.whatsapp.com/send/?phone=5511990174551"
  )
  const [showSpinner, setShowSpinner] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const changeName = e => {
    setFormName(e.target.value)
    updateMessage()
  }

  const changeCompany = e => {
    setFormCompany(e.target.value)
    updateMessage()
  }

  const changeEmail = e => {
    setFormEmail(e.target.value)
    if (regExEmail.test(e.target.value)) {
      removeError("formEmail")
    }
    updateMessage()
  }

  const changePhone = e => {
    setFormPhone(e.target.value)
    updateMessage()
  }

  const changeMessage = e => {
    setFormMessage(e.target.value)
    if (e.target.value !== "") {
      removeError("formMessage")
    }
    updateMessage()
  }

  const updateMessage = () => {
    let sendingMessage = `Mensagem através do site da SpeedCam%0A`
    sendingMessage += `${formName !== "" ? "Nome: " + formName + "%0A" : ""}`
    sendingMessage += `${
      formCompany !== "" ? "Empresa: " + formCompany + "%0A" : ""
    }`
    sendingMessage += `${formEmail !== "" ? "email: " + formEmail + "%0A" : ""}`
    sendingMessage += `${
      formPhone !== "" ? "telefone: " + formPhone + "%0A" : ""
    }`
    sendingMessage += `${
      formMessage !== "" ? "Mensagem: " + formMessage + "%0A" : ""
    }`
    const url =
      "https://api.whatsapp.com/send/?phone=5511990174551&text=" + sendingMessage
    setZapUrl(url)
  }

  const sendEmail = evt => {
    setComMethod("email")
    verifyFields()
  }

  // const sendZap = evt => {
  //   setComMethod("whatsapp")
  //   verifyFields()
  // }

  const verifyFields = () => {
    // console.log(formName)
    // console.log(formCompany)
    // console.log(formEmail)
    // console.log(formPhone)
    // console.log(formMessage)
    let errorFieldAux = []
    if (!regExEmail.test(formEmail)) {
      errorFieldAux.push("formEmail")
    }
    if (formMessage === "") {
      errorFieldAux.push("formMessage")
    }
    if (errorFieldAux.length > 0) {
      setErrorFields(errorFieldAux)
    } else {
      // console.log(commMethod)
      if (commMethod === "whatsapp") {
      }
    }

    sendEmailToAPI()
    setShowSpinner(true)
  }

  const sendEmailToAPI = async () => {
    const data = {
      referer: typeof window !== "undefined" ? window.location.href : "",
      name: formName,
      email: formEmail,
      company: formCompany,
      message: formMessage,
      phone: formPhone,
      language: "pt-br",
    }
    await sendEmailAPI(data).then(r => {
      setShowSpinner(false)
      setShowModal(true)
    })
  }

  const closeModal = () => {
    setShowModal(false)
    setFormName("")
    setFormEmail("")
    setFormCompany("")
    setFormMessage("")
    setFormPhone("")
  }

  const removeError = errName => {
    if (errorFields.includes(errName)) {
      let errorFieldAux = errorFields
      errorFieldAux.splice(errorFieldAux.indexOf(errName), 1)
      setErrorFields(errorFieldAux)
    }
  }

  return (
    <>
      <Metatags
        title="Speedcam Brasil - Contato"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <div className={`${showModal ? "block" : "hidden"}`}>
        <div
          id="defaultModal"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full "
        >
          <div className="relative p-4 w-full max-w-2xl h-full md:h-auto m-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="p-6 space-y-6">
                <p className=" text-xl leading-relaxed text-gray-500 dark:text-gray-400">
                  Seu email foi enviado corretamente. Obrigado.
                </p>
              </div>
              <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button
                  onClick={env => closeModal()}
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavBar />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>Contato</i>
        </h1>
        <div className="h-20" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
        <div className="col-span-0 md:col-span-1" />
        <div className="col-span-1 md:col-span-2">
          <div className="w-full ">
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block  text-white text-base font-bold mb-2"
                  htmlFor="grid-name"
                >
                  Nome
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-name"
                  type="text"
                  value={formName}
                  onChange={env => changeName(env)}
                />
              </div>
              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block   text-white text-base font-bold mb-2"
                  htmlFor="grid-company"
                >
                  Empresa
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-company"
                  type="text"
                  value={formCompany}
                  onChange={env => changeCompany(env)}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block   text-white text-base font-bold mb-2"
                  htmlFor="grid-mail"
                >
                  email
                </label>
                <input
                  className={`${
                    errorFields.includes("formEmail") ? "border-red-500 " : ""
                  }appearance-none block w-full bg-gray-200 text-gray-700 border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-mail"
                  type="text"
                  value={formEmail}
                  onChange={env => changeEmail(env)}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block   text-white text-base font-bold mb-2"
                  htmlFor="grid-phone"
                >
                  Telefone
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-phone"
                  type="text"
                  value={formPhone}
                  onChange={env => changePhone(env)}
                />
              </div>
              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block   text-white text-base font-bold mb-2"
                  htmlFor="grid-message"
                >
                  Mensagem
                </label>
                <textarea
                  className={`${
                    errorFields.includes("formMessage") ? "border-red-500 " : ""
                  }appearance-none block w-full bg-gray-200 text-gray-700 border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-message"
                  rows={5}
                  value={formMessage}
                  onChange={env => changeMessage(env)}
                />
              </div>
              <div className="py-3 px-4">
                <button onClick={evt => sendEmail(evt)}>
                  <div className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2">
                    Enviar por email <Icon size={"25px"} icon={envelopeO} />
                  </div>
                </button>
                <div
                  role="status"
                  className={`${showSpinner ? "" : "hidden"} mt-3`}
                >
                  <svg
                    aria-hidden="true"
                    className="mr-2 w-8 h-8 text-sc-gray animate-spin dark:text-yellow-400 fill-yellow-400"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#f3c311"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#222222"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              <div className="py-3 px-4">
                <a href={zapUrl} target="_blank" rel="noopener noreferrer">
                  <div className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2">
                    Enviar por whatsapp <Icon size={"25px"} icon={whatsapp} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-12" />

      <Footer />
    </>
  )
}

export default Contato
